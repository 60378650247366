.search-container {
  width: 100%;

  .render-input-container {
    position: relative;
  }

  .search-explantion-icon {
    position: absolute;
    right: 25px;
    margin-top: 7px;
    cursor: pointer;
  }

  .clear-button {
    position: absolute;
    right: 0px;
    width: 24px;
    height: 24px;
    margin-top: 7px;

    .icon {
    }
  }

  .MuiAutocomplete-endAdornment {
    transform: translateY(-4px);
  }
}
