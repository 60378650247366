@import "../../styles/variables.scss";

$left-side-with: 400px;
$robot-model-size: 30vh;

.robot-models-body {
  .robot-models-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    overflow-y: hidden;
  }

  .robot-models-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;
  }

  .robot-models-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;

    .robot-image-container {
      max-width: $robot-model-size;
      max-height: $robot-model-size;
      width: $robot-model-size;
      height: $robot-model-size;
      min-width: $robot-model-size;
      min-height: $robot-model-size;
    }

    .img-robot-mode {
      max-width: 350px;
      max-height: 350px;
    }
  }
}

.robot-models-modal {
  .img_container {
    position: relative;
    display: inline-block;
    text-align: center;

    .img-robot-mode {
      max-width: 350px;
      max-height: 350px;
    }
  }

  .float-button-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .float-button {
      width: 100%;
      height: 100%;
      display: none;
      border: none;
      font-size: 4em;
    }
  }
}
