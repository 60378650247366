@import "../../styles/variables.scss";

$left-side-with: 650px;

.users-body {
  .users-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    border-right: $line-style;
    overflow-y: hidden;
  }

  .users-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;
  }

  .users-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .user-view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;
  }
}

// .users-list-pager {
//     width: 50%;
//     max-width: $left-side-with;
//     margin-top: 12px;
// }
