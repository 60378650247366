.company-structure {
  .row-header-td {
    width: 10px;
    padding-top: 8px;
  }

  .current-node-icon {
    width: 0.9em;
  }

  .current-node-inherited {
    width: 0.9em;
  }

  .original-node-icon-pin {
    font-size: 1em;
  }

  .original-node-icon-start {
    font-size: 1em;
    margin-top: -3px;
  }

  .icon-td {
    width: 24px;
    max-width: 24px;
  }
}

.modal-data-inherited {
  .original-node-icon-pin {
    font-size: 1em;
  }

  .original-node-icon-start {
    font-size: 1em;
    margin-top: -3px;
  }
}
