@import "../../styles/variables.scss";

$left-side-with: 700px;

.custom-fields-body {
  .custom-fields-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    overflow-y: hidden;

    .drop-down-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .custom-fields-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;

    .col-description {
      width: 350px;
      max-width: 350px;
    }

    .custom-fields-list-pager {
      width: 100%;
      max-width: $left-side-with;
      border-top: $line-style;
      border-radius: 0;
    }
  }

  .view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;

    .col-title {
      width: 10em;
    }
  }

  .custom-fields-modal-body {
    .td-delete {
      width: 40px;
    }

    .alias-container {
      max-height: 20vh;
      overflow-y: auto;
    }
  }
}
