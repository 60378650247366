@import "../../styles/variables.scss";

$left-side-with: 500px;

.dealers-body {
  .dealers-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    overflow-y: hidden;
  }

  .dealers-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;
  }

  .dealers-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .dealer-view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;
  }
}
