//LP1
@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast {
  min-height: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  padding-bottom: 4px;
}

.Toastify__toast-container {
  width: 30vw;
  max-width: 600px;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: rgb(211, 47, 47);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: rgb(46, 125, 50);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: rgb(237, 108, 2);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: rgb(2, 136, 209);
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-title-container {
  padding-left: 2px;
  font-size: 1.3em;
  font-style: oblique;
}

.Toastify__toast-icon-container {
  max-width: 24px;
  min-width: 24px;
  width: 20px;
  height: 24px;
  vertical-align: middle;
  padding-left: 5px;
}

.Toastify__toast-text-container {
  padding-left: 15px;
}
