@import "../../styles/variables.scss";

#onboarding-dialog {
  margin: 0px auto;
  #onboarding-header {
    padding-top: 0px;
    padding-right: 20px;
    h3 {
      text-transform: capitalize;
      line-height: 50px;
      padding-left: 16px;
    }
    svg {
      margin-bottom: 4px;
      margin-right: 10px;
    }
  }

  #onboarding-body {
    width: 70vw;
    padding-left: 30px;
    padding-right: 30px;
    min-height: 70vh;

    .accept-conditions {
      line-height: 40px;
    }
  }

  @media (max-width: 1160px) {
    #onboarding-body {
      width: 90vw;
    }
  }

  @media (max-width: 900px) {
    #onboarding-body {
      width: 95vw;
    }
  }

  #workflows-list {
    ul {
      margin: 0px;
      padding-left: 0px;
      li {
        padding-left: 16px;
      }
    }
  }

  .onboarding-container {
    min-height: 70vh;
    height: 70vh;

    .onboarding-content {
      max-height: 70vh;
      height: 70vh;
      overflow-y: scroll;
    }
  }

  .workflow-label {
    line-height: 40px;
    list-style-type: none;
    font-size: 1rem;
  }

  .current-workflow-label {
    margin-right: 30px;
  }

  #onboarding-footer {
    margin-right: 30px;
  }

  .workflow-component-title {
    text-align: left;
    margin-top: 0px;
    line-height: 40px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 24.336px;
    font-weight: bold;
    text-transform: capitalize;
    max-height: 80px;
    word-break: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .workflow-component-message {
    flex-basis: 100%;
    flex-grow: 1;
    max-height: 11vh;
    line-height: 2vh;
    word-break: normal;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  @media (max-height: 900px) {
    .workflow-component-message {
      line-height: 1.95vh;
    }
  }

  .workflow-component-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 30px;
  }
}
