@import "../../styles/variables.scss";

.profile-body {
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  font-family: $font-family-roboto;

  section {
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .title {
    font-size: 1.5em;
  }

  .section-container {
    display: inline-flex;
  }

  .section-title {
    font-weight: bold;
    font-size: 1.5em;
  }

  .option-container {
    display: inline-flex;
    font-size: 1em;

    .w-14-em {
      min-width: 14em !important;
      width: 14em !important;
    }

    .option-title {
      font-size: 1.2em;
    }

    .option-subtitle {
      min-width: 10em !important;
      width: 10em !important;
    }
  }

  .separator {
    height: 1px !important;
    max-height: 1px !important;
    padding: 0;
    margin: 0;
  }

  .child-sub-title {
    margin-top: 20px;
  }
}

.profile-password-modal {
  .error-message {
    font-size: 0.8rem;
  }
}

.profile-edit-modal {
  .input-label {
    padding-top: 14px;
  }

  .data-div {
    height: 42px;
    margin-bottom: 4px;
  }
}
