$earlybyte-pink: #c83771;

$line-color: rgba(0, 0, 0, 0.12);
$line-style: 1px solid $line-color;
$background-transparent: rgba(17, 143, 243, 0.08);
$font-family-roboto: "Roboto", "Helvetica", "Arial", sans-serif;
$kemaro-sky-blue: #118ff3;
$kemaro-sky-blue-transparent: rgba(17, 143, 243, 0.3);
$light-grey: rgba(0, 0, 0, 0.38);
$footer-height: 56px;

$menu-item-color: rgba(0, 0, 0, 0.87);
$menu-item-color-icon: #686d73;
$menu-item-hover-background: #f5f5f5;
$menu-item-current-background: rgba(25, 118, 210, 0.08);
$menu-item-current-background-hover: rgba(25, 118, 210, 0.12);

$current-row-background-color: #e3eefa;

$button-color: white;
$button-background: #1976d2;
$button-color-hover: white;
$button-background-hover: #1565c0;
$button-color-disabled: white;
$button-background-disabled: #a4a4a4;

$button-icon-primary-color: #118ff3;
$button-icon-disabled-color: #9f9f9f;
$button-icon-error-color: rgb(211, 47, 47);

$highlight-color-robot: #f16c24;
$highlight-color-serial-number: #a36b62;
$highlight-color-name: #934d4d;
$highlight-color-location: #7b7b7b;
$highlight-color-model: #2e48f5;
$highlight-color-customer: #78b84b;
$highlight-color-dealer: #e1a626;
$highlight-color-contact: #0ec96f;
$highlight-color-manufacturer: #9e41b8;
$highlight-color-mission: #7a8a49;
$highlight-color-status: #118ff3;
$highlight-color-country: #10acb1;
$highlight-color-dashboard-calculation: #54699f;
$highlight-color-text-search: #4d4d4d;
$highlight-color-various: #9246f5;
$highlight-color-error-codes: #fb4141;

$user-role-icon-color: rgba(0, 0, 0, 0.2);
