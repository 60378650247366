@import "../../styles/variables.scss";

$left-side-with: 240px;
$left-side-with-small: 60px;
$padding-left: 8px;
$padding-right: 8px;

$main-panel-me-height: 64px;
$main-panel-manufacurers-height: 55px;
$main-panel-footer-height: 140px;

.main-panel {
  width: $left-side-with;

  .main-panel-me {
    padding-left: $padding-left;
    padding-right: $padding-right;
    width: $left-side-with;
    height: $main-panel-me-height;
    max-height: $main-panel-me-height;
    min-height: $main-panel-me-height;
    overflow-y: hidden;
    overflow-x: hidden;

    .user-name {
      font-family: $font-family-roboto;
      font-size: 1.5em;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 160px;
      white-space: nowrap;
    }

    .tenant-name {
      font-family: $font-family-roboto;
      font-size: 1em;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 160px;
      white-space: nowrap;
    }
  }

  .main-panel-manufacturers {
    padding-left: $padding-left;
    padding-right: $padding-right;

    .form-select {
      border: none;
      font-family: $font-family-roboto;
      font-size: 1.2em;
      padding-right: 0;
      padding-left: 0;
      cursor: pointer;
    }

    .form-select:focus {
      border-color: none;
      outline: 0;
      box-shadow: none;
    }
  }

  .main-panel-menu {
    flex: 1 1 auto;
    overflow: hidden;
    overflow-y: auto;
    height: calc(
      100% - $main-panel-me-height - $main-panel-manufacurers-height -
        $main-panel-footer-height
    );

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      font-size: 1rem;

      .li-icon {
        width: 56px;
      }
    }

    a {
      text-decoration: none;
    }

    // li:hover {
    //   background-color: var(--menu-item-hover-background) !important;
    // }
  }

  .main-panel-footer {
    font-family: $font-family-roboto;
  }
}

.main-panel-small {
  width: $left-side-with-small !important;

  .main-panel-me {
    width: $left-side-with-small !important;
  }

  .main-panel-menu {
    height: calc(100% - $main-panel-me-height);
  }

  .span-icon {
  }
}
