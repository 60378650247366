@import "../../styles/variables.scss";

#file-accept {
  margin-top: 5vh;
  width: 100%;
  display: inline-flex;
}

@media (max-height: 900px) {
  #file-accept {
    margin-top: 0px;
  }
}

#file-object {
  margin-top: 10px;
  width: 100%;
  height: 27vh;
  min-height: 27vh;
  border: $line-style;
}

@media (max-height: 768px) {
  #file-object {
    height: 24vh;
    min-height: 24vh;
  }
}

#file-download {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: center;
  padding-top: 10px;

  svg {
    margin-bottom: 2px;
    margin-right: 5px;
  }

  a {
    text-decoration: none;
  }
}

#file-title {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: left;
  line-height: 40px;
}

#file-message {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: left;
  padding-top: 40px;
}
