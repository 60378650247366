@import "../../../styles/variables.scss";

$left-side-with: 240px;
$left-side-with-small: 70px;

.kemaro-dashboard-select {
  border-left: 0 !important;
  border-right: 0;
}

.kemaro-dashboard-economy-filter {
  .title {
    font-family: $font-family-roboto;
    font-size: 1.5em;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    font-weight: bold;
  }

  .filter-title {
    padding-top: 12px;
  }

  .customer-filter {
    margin-bottom: 3px;

    .MuiInput-input {
      margin-bottom: 1px !important;
    }
  }

  .filter-links-container {
    padding-right: 32px;
  }

  .filter-row-container {
    padding-left: 12px;
    padding-right: 20px;
  }
}

.kemaro-dashboard-economy-performance {
  border-radius: 4px;
  padding: 20px !important;

  .title {
    font-size: 1.2rem;
    font-family: $font-family-roboto;
  }

  .subtitle {
    font-size: 1rem;
    font-family: $font-family-roboto;
  }

  .td-description {
    font-size: 1rem;
    font-family: $font-family-roboto;
    height: 24px;
  }

  .td-value {
    font-size: 1rem;
    font-family: $font-family-roboto;
    text-align: right;
    height: 24px;
  }

  .td-button {
    width: 24px;
    height: 24px;
  }
}

.kemaro-dashboard-economy-statistics {
  border-radius: 4px;
  padding: 20px !important;

  .title {
    font-size: 1.5rem;
    font-family: $font-family-roboto;
    text-align: center;
  }

  .sub-title {
    font-size: 1.2rem;
    font-family: $font-family-roboto;
    text-align: center;
    margin-top: -5px;
  }

  .weeks-filter {
    margin-top: -20px;
  }
}

.kemaro-dashboard-economy-cost {
  border-radius: 4px;
  padding: 20px !important;

  .title {
    font-size: 1.5rem;
    font-family: $font-family-roboto;
  }

  .td-description {
    font-size: 1rem;
    font-family: $font-family-roboto;
  }

  .td-value {
    font-size: 1rem;
    font-family: $font-family-roboto;
    text-align: right;
  }

  .color-div {
    height: 20px !important;
    width: 20px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .div-maks {
    height: 250px !important;
    max-height: 250px !important;
    min-height: 250px !important;
  }

  .div-first {
    height: 130px !important;
    max-height: 130px !important;
    min-height: 130px !important;
  }

  .div-data {
    height: 22px !important;
    max-height: 22px !important;
    min-height: 22px !important;
  }

  .footer-title {
    font-size: 1.2rem;
    font-family: $font-family-roboto;
    text-align: center;
  }

  .amortization-pie {
    margin-left: auto;
    margin-right: auto;
    width: 200px !important;
  }

  .amortization-pie-inner-text {
    font-size: 6.5em;
    font-family: $font-family-roboto;
    text-align: center;
    margin-top: 20px;
  }

  .amortization-pie-inner-title {
    font-size: 1.1rem;
    margin-top: -25px;
  }

  .cost-efficiency {
    font-size: 5em;
    font-family: $font-family-roboto;
    text-align: center;
  }

  .pie-container {
    width: 100%;
    position: relative;

    .pie-div {
      height: 200px;
      width: 100%;
      position: absolute;
    }

    .pie-amotization {
      height: 200px;
      width: 100%;
      position: absolute;

      .amortization-pie-inner-text {
        font-size: 5em;
        padding-top: 0.35em;
      }
    }
  }

  .cost-efficiency-container {
    padding-top: 290px;
  }
}

.no-data-container {
  position: fixed;
  top: 245px;
  height: 80%;
  width: calc(calc(100vw - $left-side-with) - 8px);
  z-index: 100;

  .no-data {
    border-radius: 4px;
    padding: 14px 16px;
    position: absolute;
    top: calc(45% - 54px);
    left: calc(50% - 200px);
    width: 400px;
    height: 54px;
  }
}

.kemaro-dashboard-economy-robots-modal {
  .robot-dot {
    font-size: 0.5rem;
  }
}
