@import "../../styles/variables.scss";

#link-accept {
  margin-top: 11vh;
  width: 100%;
  display: inline-flex;
}

#link-ref {
  flex-basis: 100%;
  flex-grow: 1;
  margin-top: 10vh;
  height: 8vh;
  max-height: 8vh;
  line-height: 2.05vh;
  overflow: hidden;
  word-break: break-all;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  a {
    text-decoration: none;
  }
}

@media (max-height: 900px) {
  #link-accept {
    margin-top: 6vh;
  }

  #link-ref {
    margin-top: 8vh;
  }
}

#link-checkbox-text {
  padding-right: 10px;
  padding-top: 4px;
}

#link-workflow-component-message {
  flex-basis: 100%;
  flex-grow: 1;
  max-height: 21vh;
  line-height: 2vh;
  word-break: normal;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
