@import "../../styles/variables.scss";

$left-side-with: 700px;

.on-boarding-body {
  .on-boarding-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    border-right: $line-style;
    overflow-y: hidden;
  }

  .on-boarding-list-items {
    width: 100%;
    height: calc(50% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;
  }

  .on-boarding-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;
  }
}

.workflow-translated-data-panel {
  .tool-bar {
    min-width: 200px;
    max-width: 500px;
  }

  .col-title {
    max-width: 15vw;
    overflow: hidden;
  }

  .col-language {
    max-width: 10vw;
    overflow: hidden;
  }
}

.on-boarding-modal-body {
  .border-bottom {
    border-bottom: $line-style;
  }

  .td-delete {
    width: 40px;
  }

  .alias-container {
    max-height: 20vh;
    overflow-y: auto;
  }
}
