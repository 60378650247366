.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.main-container {
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.default-font-size {
  font-size: 0.875rem;
}

.warning-icon {
  font-size: 2rem;
}

.me-g {
  margin-left: -12px;
}
