@import "./variables.scss";
@import "./toast.scss";
@import "./bootstrap.scss";

.page-content {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;

  .page-header {
    flex: 0 1 auto;
  }

  .page-body {
    flex: 1 1 auto;
    overflow: hidden;
    overflow-y: auto;
  }

  .page-footer {
    flex: 0 1 auto;
    height: 70px;
    max-height: 70px;
    min-height: 70px;
  }
}

.puffLoader-container {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.close-modal {
  position: absolute !important;
  right: 5px;
  top: 5px;
  font-size: 1.2em !important;
}

.application-modal-body {
  margin: 10px;
}

.application-modal {
  font-family: $font-family-roboto;

  .application-modal-header {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 5px;
    font-size: 1.3em;
    border-bottom: 0;

    h3 {
      margin-bottom: 0 !important;
      margin-top: 10px !important;
      padding-left: 10px;
      font-family: $font-family-roboto;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  .application-modal-body {
    padding: 0px 30px;
    max-height: 70vh;
    overflow-y: auto;
  }

  .application-modal-footer {
    padding: 10px;
    text-align: right;
    border-top: 0;
    overflow-x: hidden;
  }

  .modal-cancel-button {
    margin-left: 10px;
  }
}

.modal-content {
  font-size: 0.875rem;
  line-height: 1.43;
}

.MuiDialog-paperWidthMd {
  min-width: 700px;
  width: 700px;
  max-width: 700px;
}

.header-cell {
  font-weight: bold !important;
}

.list-pager {
  width: 100%;
  margin-top: 12px;
}

.table-cell {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.table-buttons {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bordered {
  border: $line-style;
}

.rounded {
  border-radius: 10px;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.top {
  vertical-align: top !important;
}

.no-border {
  border-bottom: 0 !important;
}

.MuiButton-outlined {
  border-width: 2px !important;
}

.MuiSwitch-root {
  margin-left: -12px;
}

.w-0 {
  width: 0;
}

.w-50 {
  width: 50%;
}

.default-text {
  font-size: 1rem;
  font-family: $font-family-roboto;
}

.no-wrap {
  white-space: nowrap;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
}

.child-sub-title {
  font-size: 1.2em;
  margin-top: 50px;
  margin-bottom: 0px;
  font-weight: 600;
}

.primary-text {
}

.one-button-container {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}

.two-buttons-container {
  width: 115px;
  max-width: 115px;
  min-width: 115px;
}

.three-buttons-container {
  width: 160px;
  max-width: 160px;
  min-width: 160px;
}

.four-buttons-container {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.v-top {
  vertical-align: top;
}

.input-label {
  padding-top: 11px;
}

.view-container {
  width: 100%;
}

.line-height-one {
  line-height: 1em !important;
}

.table-cell-contac {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.header-line {
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
  padding-top: 10px;
}

.banner {
  width: 100%;
  font-family: $font-family-roboto;
  font-size: 0.85em;
  border-left: 4px solid;
  padding: 20px;
  border-radius: 3px;
}

.no-results {
  margin-left: 20px;

  p {
    font-family: $font-family-roboto;
    font-size: 1.25em;
  }
}

.autocomplete-template-disable {
  font-style: italic;
  color: $button-icon-disabled-color;
}

.autocomplete-template {
  background-color: transparent;
}

.autocomplete-template:hover {
  background-color: #f5f5f5;
}

.autocomplete-template-select {
  background-color: #edf4fb !important;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.tabs-nav-container {
  border-bottom: $line-style;
}

.tool-tip-text {
  font-size: 1.1em;
}

.no-background {
  background-color: transparent !important;
}

.border-white-1 {
  border: 1px solid white;
}

.button-with-text {
  width: 9em;
}

.column-titles {
  border-bottom: $line-style;
  font-size: 1.2em;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-disabled {
  color: $light-grey;
}

.text-linebreak {
  white-space: pre-line;
}

.container {
  overflow: hidden;
  white-space: nowrap;
}

.scrolling {
  animation: marquee 15s linear infinite;
  display: inline-block;
  padding-right: 10px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.td-date {
  width: 10em;
}

.td-start-end-date {
  width: 15em;
}

.MuiInputBase-fullWidth {
  padding-top: 0px !important;
}

.no-vertical-scroll {
  overflow-y: hidden !important;
}

.icon-as-buttom {
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.date-time-col {
  width: 10rem;
}

.table-transparent {
  td {
    background-color: transparent !important;
  }
}
