//LP1
@import "./variables.scss";

$accordion-chevron: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='rgba(0, 0, 0, 0.6)'%3e%3cpath fill-rule='evenodd' d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3e%3c/svg%3e");

.accordion-item {
  margin-bottom: 0px;

  &:not(:first-of-type) {
    border-top: $line-style;
  }
}

.accordion-button {
  &:focus {
    box-shadow: none;
  }

  &:not(.collapsed) {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    box-shadow: none;
  }
}

.accordion-button.collapsed {
  &::after {
    background-image: $accordion-chevron;
  }

  :not(.collapsed)::after {
    background-image: $accordion-chevron;
  }
}

.accordion-item-expanded {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.accordion-icon-left-side {
  .accordion-button {
    padding-left: 5px;
    height: 50px;
  }

  .accordion-button.collapsed {
    &::before {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-right: 20px;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
      background-image: $accordion-chevron;
    }

    &::after {
      background-image: none;
    }
  }

  .accordion-button:not(.collapsed) {
    &::before {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-right: 20px;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
      background-image: $accordion-chevron;
      transform: var(--bs-accordion-btn-icon-transform);
    }

    &::after {
      background-image: none;
    }
  }
}

.accordion-no-icon {
  .accordion-button.collapsed {
    &::after {
      background-image: none;
    }
  }

  .accordion-button:not(.collapsed) {
    &::after {
      background-image: none;
    }
  }
}
