.kemaro-robot-deployment-history-modal {
  max-height: 50vh;

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 49px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dealer {
    font-size: 1.2rem;
  }

  .customers {
    font-size: 1.1rem;
  }

  .customers-icon {
    font-size: 1.1rem;
    vertical-align: text-top;
  }

  .location-icon {
    font-size: 1.1rem;
  }

  .accordion-summary-open {
    border-bottom: 1px solid gray;
  }

  .company-name-td {
    max-width: 370px;
    min-width: 370px;
  }

  .empty-td {
    min-width: 26px;
    max-width: 26px;
  }
}
