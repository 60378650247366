@import "../../styles/variables.scss";

.badges-presenter {
  .rounded-pill {
    border: 1px solid;
    font-weight: normal;
  }

  .category-title {
    font-family: $font-family-roboto;
    font-size: 0.75em;
    font-weight: normal;
  }

  .btn-sm-icon {
    width: 0.6em;
    height: 0.6em;
  }
}
