@import "../../../styles/variables.scss";

.applicacion-bar-container {
  min-height: 64px;
  max-height: 64px;
  display: flex;
  position: relative;
  align-items: center;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .title {
    font-size: 1.25rem;
    font-family: $font-family-roboto;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .search-icon-td {
    width: 24px;
  }

  .search-input-container {
    border-radius: 4px;
    width: 400px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 2px;
    margin-right: 10px;
  }

  .search-input-icon {
    font-size: 1.5rem;
  }

  .search-input {
    margin-right: 10px;
    border: 0;
    font-family: $font-family-roboto;
    font-size: 1rem;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff80;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff80;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff80;
  }

  .search-input:focus,
  input:focus {
    outline: none;
  }

  .clear-td {
    text-align: end;
    width: 15px;
  }

  .clear-input-icon {
    font-size: 1rem;
    vertical-align: top;
  }
}
