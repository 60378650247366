@import "../../styles/variables.scss";

.not-allowed-workflow-component-message {
  flex-basis: 100%;
  flex-grow: 1;
  padding-top: 10px;
  max-height: 40vh;
  line-height: 2vh;
  word-break: normal;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 19;
  overflow: hidden;
}

@media (max-height: 900px) {
  .workflow-component-message {
    line-height: 1.95vh;
  }
}
