@import "../../styles/variables.scss";

.onboarding-contact-container {
  height: 50vh;
}

@media (max-height: 900px) {
  .onboarding-contact-container {
    height: 50vh;
  }
}

@media (max-height: 850px) {
  .onboarding-contact-container {
    height: 55vh;
  }
}

.onboarding-inputlabel {
  text-align: left;
}

.application-modal .workflow-component-container .application-modal-header {
  padding-top: 0px;
}
