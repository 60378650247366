@import "../../../styles/variables.scss";

$left-side-with: 700px;
$robot-model-size: 30vh;
$map-width: 250px;

.kemaro-missions-body {
  .drop-down-title {
    width: 10rem;
  }

  .MuiAlert-message {
    width: 100%;
  }
  .kemaro-missions-left-side {
    width: 100%;
    height: 100%;
    min-width: $left-side-with;
    max-width: $left-side-with;
    overflow-y: hidden;

    .success {
      width: 22px !important;
      height: 22px !important;
      max-width: 22px !important;
      max-height: 22px !important;
      margin-top: 3px;
    }

    .error {
      width: 22px !important;
      height: 22px !important;
      max-width: 22px !important;
      max-height: 22px !important;
      margin-top: 3px;
    }

    .internal {
      width: 22px !important;
      height: 22px !important;
      max-width: 22px !important;
      max-height: 22px !important;
      margin-top: 3px;
    }

    .icon-container {
      min-width: 50px !important;
      width: 50px !important;
      max-width: 50px !important;
    }
  }

  .kemaro-missions-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;

    .display-timezone-title {
      font-weight: normal;
      font-size: 1rem;
    }
  }

  .kemaro-missions-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .kemaro-missions-view-container {
    width: 100%;
    height: calc(100% - $footer-height);
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;

    .robot-name {
      font-size: 1.6em;
      font-weight: 600;
      margin-right: 20px;
      white-space: nowrap;
    }

    .robot-location {
      font-size: 1.2em;
      margin-right: 20px;
      white-space: nowrap;
    }

    .robot-serial-number-title {
      font-size: 1em;
      font-weight: bold;
      line-height: 1;
    }

    .robot-serial-number-value {
      font-size: 1.2em;
    }

    .summary-container {
      padding: 20px;

      .title {
        font-size: 1.2em;
      }

      .td-map {
        width: $map-width;
        max-width: $map-width;
      }

      .image-small {
        height: auto;
        width: auto;
        max-width: $map-width;
        max-height: $map-width;
      }

      .summary-table {
        .summary-title {
          padding-bottom: 0px !important;
          border: 0px !important;
        }

        .summary-value-column {
          text-align: right;
        }

        .map-container {
          text-align: center;
        }
      }
    }

    .runs-container {
      padding: 20px;

      .title {
        font-size: 1.2em;
      }

      .runs-title {
        font-weight: bold;
        margin-bottom: 20px;
      }

      .accordion-container {
        .accordion-with-out-bottom {
          background-color: transparent;
          border-bottom: 0;
        }

        .accordion {
          background-color: transparent;
        }

        .accordion-detail {
          background-color: transparent;
        }
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 49px;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .run-header-table {
        font-family: $font-family-roboto;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
      }

      .run-header-icon-cell {
        width: 24px;
        padding: 0;
      }

      .run-header-Icon {
        width: 22px !important;
        height: 22px !important;
        max-width: 22px !important;
        max-height: 22px !important;
        margin-top: 3px;
      }

      .run-detail-table {
        font-family: $font-family-roboto;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
      }

      .run-detail-icon-cell {
        width: 20px;
        padding: 0;
        padding-left: 1px;
      }

      .run-detail-value-cell {
        text-align: right;
        padding-right: 1px;
      }

      .run-detail-icon-cell {
        width: 22px !important;
        height: 22px !important;
        max-width: 22px !important;
        max-height: 22px !important;
        margin-top: 3px;
      }

      .run-success {
        width: 22px !important;
        height: 22px !important;
        max-width: 22px !important;
        max-height: 22px !important;
        margin-top: 3px;
      }

      .run-error {
        width: 22px !important;
        height: 22px !important;
        max-width: 22px !important;
        max-height: 22px !important;
        margin-top: 3px;
      }

      .td-8 {
        width: 8em;
        text-align: right;
        white-space: nowrap;
      }

      .td-6 {
        width: 6em;
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  .kemaro-missions-view-container-h100 {
    height: 100%;
  }

  .send-report {
    height: $footer-height;

    .action-button {
      width: 9em;
    }
  }

  .mission-error {
    border-radius: 4px;
    padding: 13px 16px;

    .mission-error-icon {
      font-size: 1.35rem;
    }
  }
}

$map-container-size: 20rem;
.kemaro-missions-new-body {
  .run {
    .show-data {
      .title {
        font-size: 0.875rem;
      }
    }

    .error {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid black;
      margin-bottom: 5px;
    }
  }

  .map-container {
    max-width: $map-container-size;
    max-height: $map-container-size;
    width: $map-container-size;
    min-width: $map-container-size;
    margin-left: auto;
    margin-right: auto;

    .img-map {
      height: auto;
      width: auto;
      max-height: $map-container-size;
      max-width: calc($map-container-size - 24px);
    }
  }

  .ui-error {
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .auto-complete {
    padding-top: 8px;
    padding-bottom: 4px;
    margin-bottom: 3px;

    .MuiInput-input {
      margin-bottom: 1px !important;
    }
  }
}
