.search-explanation {
  font-size: 0.75em;

  .icon {
    font-size: 1rem;
  }
}

.custom-field-autocomplete {
  padding-top: 8px;
  padding-bottom: 4px;
}
