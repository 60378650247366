//LP1
@import "../../../styles/variables.scss";

$left-side-with: 700px;
$left-side-min-with: 500px;
$robot-model-size: 12rem;
$recent-bug: #fca015;

.kemaro-robot-body {
  .kemaro-robot-left-side {
    width: 100%;
    height: 100%;
    min-width: $left-side-min-with;
    max-width: $left-side-with;
    overflow-y: hidden;
  }

  .kemaro-robot-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;

    .td-3-icon {
      width: 130px;
    }

    .td-2-icon {
      width: 100px;
    }
  }

  .kemaro-robot-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .kemaro-robot-view-container {
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;

    .title {
      text-align: start;
      font-size: 1.5em;
    }

    .robot-image-container {
      max-width: $robot-model-size;
      max-height: $robot-model-size;
      width: $robot-model-size;
      height: $robot-model-size;
      min-width: $robot-model-size;
      min-height: $robot-model-size;
    }

    .robot-image {
      height: auto;
      width: auto;
      max-width: $robot-model-size;
      max-height: $robot-model-size;
    }

    .table-model-threshold {
      width: auto;
    }
  }

  .kemaro-robot-info {
    td {
      padding-top: 0;
    }

    .icon-button-primary-inline {
      font-size: 1.1rem;
    }

    .td-label {
      height: 20px;
      max-height: 20px;
      min-height: 20px;
      vertical-align: bottom;
      width: 0px;
      white-space: nowrap;
      padding-right: 30px;
    }

    .td-data {
      height: 20px;
      max-height: 20px;
      min-height: 20px;
      vertical-align: bottom;
      padding-bottom: 0;
    }

    tr td:first-child {
      padding-left: 0;
    }

    .company-structure {
      .parents {
        font-size: 0.75em;
      }

      .icon {
        font-size: 0.75em;
      }
    }

    .child-parameter {
      font-size: 1rem;
    }
  }

  .robot-model {
    font-size: 1.1em;
    border-radius: 8px;
  }

  .kemaro-robot-tags {
    .title {
      margin-bottom: 0.25em;
      font-size: 1.5em;
    }
  }

  .kemaro-robot-cleaning-information {
    text-align: center;

    .krci-container {
      border-width: 1px 2px 3px 2px;
      border-style: solid;
      border-radius: 10px;
    }

    .krci-title {
      margin-bottom: 0.25em;
      font-size: 1.1em;
      font-weight: bold;
    }

    .krci-value {
      font-size: 4em;
    }

    .krci-label {
      padding-left: 4px;
      font-size: 1.2em;
    }
  }

  .kemaro-robot-cleaned-area-history {
    .title {
      text-align: start;
      font-size: 1.5em;
    }

    .label {
      font-size: 0.8em;
    }

    .btn-link {
      text-decoration: none;
    }

    .btn-link-current {
      font-weight: bold;
    }

    .tooltip-container {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
    }
  }

  .kemaro-robot-customer-contact {
    text-align: start;

    .title {
      font-size: 1.5em;
    }

    .td-label {
      width: 0px;
      white-space: nowrap;
      padding-right: 30px;
    }
  }

  .bug-old {
  }

  .bug-recent {
  }
}

.access-settings-container {
  font-size: 1.2em;
}

.kemaro-robot-system-details {
  text-align: start;
  padding-left: 30px;
  padding-right: 30px;

  .container-with-background {
  }
}

.kemaro-robot-error-summary-body {
  .when {
    font-size: 1.1em;
  }

  .recent-title {
    hr {
      color: $recent-bug;
      opacity: 1;
    }
  }

  .old-title {
    hr {
      color: $light-grey;
      opacity: 1;
    }
  }

  .error-container {
    hr {
      color: white;
    }

    &:not(:last-child) {
      hr {
        color: $light-grey;
      }
    }
  }
}

.input-only-text {
  font-size: 16px;
}

.kemro-robot-modal {
  overflow-y: hidden !important;

  .input-label {
    .only-text {
      margin-top: 2px;
      margin-bottom: 8px;
    }
  }

  .data-div {
    height: 42px;
    overflow-y: none;
  }

  .warning-icon {
    font-size: 2rem;
  }
}

.kemro-robot-tag-modal {
  min-height: 30vh;
}

.kemaro-local-access-management-modal-body {
  .requirement {
    opacity: 0.8;
    font-size: 0.75rem;
  }

  .credential-title {
    font-size: 1.2rem;
  }

  .divider {
    height: 0px;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }
}

.kemaro-robot-error-summary-modal {
  .MuiDialog-paperWidthMd {
    width: 900px;
    max-width: 900px;
  }
}

.access-settings-modal {
  .MuiDialog-paperWidthMd {
    min-width: 800px;
    width: 800px;
    max-width: 800px;
  }
}
