@import "../../styles/variables.scss";

$left-side-with: 500px;

.customer-body {
  .customer-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    overflow-y: hidden;
  }

  .customers-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;
  }

  .customers-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .customers-view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .customer-view-container {
    padding: 20px;
  }
}
