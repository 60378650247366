
.tree-view-accordion {
  .accordion-item {
    border: 0;
  }

  .accordion-icon-tree-view {
    .accordion-button {
      padding-left: 5px;
    }

    .accordion-button.collapsed {
      &::before {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        content: "";
        background-image: var(--bs-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition);
        transform: rotate(-90deg);
      }

      &::after {
        background-image: none;
      }
    }

    .accordion-button:not(.collapsed) {
      &::before {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        content: "";
        background-image: var(--bs-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition);
        transform: rotate(0deg);
      }

      &::after {
        background-image: none;
      }
    }
  }

  .icon-button {
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
  }

  .td-icon {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    height: var(--bs-accordion-btn-icon-width);
  }

  .node-header {
    cursor: pointer;
  }

  .node-header-selected {
    cursor: pointer;
  }
}
