.user-radio-td {
  width: 42px;
}

.notification-configuration-send-test {
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}

.notification-configuration-send-test:hover {
  border-radius: 5px;
}

.pt-13-px {
  padding-top: 13px;
}
