.main-div {
  width: 100%;
  height: 100%;
}

td {
  padding: 0;
}

tr {
  padding: 0;
}

p {
  margin: 0;
}

$fontfamily: "Roboto", "Helvetica", "Arial", sans-serif;

.main-table {
  width: 100%;
  height: 100%;
  font-family: $fontfamily;
}

.td-first {
  height: 10vh;
}

.td-logo {
  height: 25vh;
  text-align: center;
}

.td-welcome {
  height: 12vh;
  text-align: center;
}

.td-separator-1 {
  height: 14vh;
}

.td-separator-2 {
  height: 7vh;
}

.td-robot-cloud {
  height: 0.5vh;
  text-align: center;
}

.td-last {
  height: 5vh;
}

.earlybyte {
  width: auto;
  height: 25vh;
}

.welcome-text {
  font-size: 4em;
}

.welcome-text-subtitle {
  font-size: 2em;
}

.login-paragaph {
  margin-top: 1em;
}

.login-button {
  padding: 8px 30px 8px 30px;
  border-radius: 4px;
  cursor: pointer;
  font-family: $fontfamily;
  font-weight: bold;
  text-decoration: none;
  border-width: 0px;
}

.table-brands {
  height: 100%;
  width: 100%;
  font-family: $fontfamily;
}

.td-brand-separtor {
  width: 10vh;
}

.td-brand-left {
  text-align: right;
}

.logo-brand {
  width: auto;
  height: 6vh;
}

.warning {
  .warning-text {
    font-size: 0.9em;
  }
}
