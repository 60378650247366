@import "../../styles/variables.scss";

.permissions-matrix-container {
  width: 100%;
  height: calc(100% - $footer-height);
  overflow-y: auto;
}

.permissions-matrix-container th {
  text-align: center;
}

.permissions-matrix-container thead {
  position: sticky;
  top: 0;
}

.permissions-matrix-filler {
}

.permissions-matrix-section-title {
  font-weight: bold;
}

.permissions-matrix-row-light {
}

.permissions-matrix-row-dark {
}

.permissions-matrix-icon-check {
}
