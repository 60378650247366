@import "../../../styles/variables.scss";

.unassigned-panel {
  .td-first {
    height: 10vh;
  }

  .earlybyte {
    width: auto;
    height: 15vh;
  }

  .welcome-text {
    font-size: 2.2em;
  }

  .explanation-text {
    font-size: 1.2em;
  }
}
