@import "../../../styles/variables.scss";

.input-text-list {
  .text-container {
    max-height: 20vh;
    overflow-y: auto;
  }

  .td-delete {
    width: 40px;
  }

  .badge {
    font-size: 0.9rem;
    font-weight: normal;

    &:hover {
      cursor: pointer;
    }
  }
}
