.tags-displayer {
  .badge {
    font-size: 0.875rem;
    font-weight: normal;
  }

  .icon {
    font-size: 1.2em;
  }
}
