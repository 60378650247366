@import "../../styles/variables.scss";

.maxbrain-log-body {
  .success {
    width: 22px !important;
    height: 22px !important;
    max-width: 22px !important;
    max-height: 22px !important;
    margin-top: 3px;
  }

  .error {
    width: 22px !important;
    height: 22px !important;
    max-width: 22px !important;
    max-height: 22px !important;
    margin-top: 3px;
  }

  .maxbrain-log-left-side {
    width: 100%;
    height: 100%;
    border-right: $line-style;
    overflow-y: hidden;

    .maxbrain-log-list-items {
      width: 100%;
      height: calc(100% - $footer-height);
      overflow-y: auto;

      .success-td {
        width: 30px;
      }

      .when-td {
        width: 10rem;
      }

      .action-td {
        width: 4rem;
      }

      .status-td {
        width: 15rem;
      }
    }

    .maxbrain-log-list-pager {
      width: 100%;
      border-top: $line-style;
      border-radius: 0;
    }
  }
}
