@import "../../../../styles/variables.scss";

$left-side-with: 700px;

.kemaro-cost-calculation-body {
  .kemaro-cost-calculation-left-side {
    width: 100%;
    height: 100%;
    max-width: $left-side-with;
    border-right: $line-style;
    overflow-y: hidden;
  }

  .kemaro-cost-calculation-list-items {
    width: 100%;
    height: calc(100% - $footer-height);
    max-width: $left-side-with;
    overflow-y: auto;

    .start-td {
      width: 1rem;
      max-width: 1rem;
      color: $kemaro-sky-blue;
      font-size: 10px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .currency-td {
      max-width: 3rem;
      width: 3rem;
    }

    .customers-td {
      max-width: 4rem;
      width: 4rem;
    }

    .robots-td {
      max-width: 4rem;
      width: 4rem;
    }
  }

  .kemaro-cost-calculation-list-pager {
    width: 100%;
    max-width: $left-side-with;
    border-top: $line-style;
    border-radius: 0;
  }

  .view-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $font-family-roboto;

    .header-container-data {
      div {
        height: 50px;
        padding-top: 15px;
      }
    }

    th {
      font-weight: normal;
      border-bottom: $line-style;
    }

    .button-header-th {
      width: 56px;
    }

    .locations-container {
      .customer-container {
        padding-top: 5px;
        padding-bottom: 5px;

        .title {
          font-size: 1.1rem;
        }
      }

      .child-icon {
        width: 3rem;
        max-width: 3rem;
      }

      .treeview-check-uncheck-td {
        width: 2rem;
        max-width: 2rem;
        vertical-align: middle;
      }

      .all-customers {
        .switch-container {
          width: 4rem;
          max-width: 4rem;
          text-align: right;
        }
      }

      .child-sub-title-tree {
        font-size: 1em;
      }
    }
  }
}

.kemaro-cost-calculation-locations-modal {
  .treeview-check-uncheck-td {
    width: 2rem;
    max-width: 2rem;
    vertical-align: middle;
  }
}

.kemaro-cost-calculation-recalculate-dashboard-modal {
  .button-with-text {
    width: 25em;
  }
}

.dashboard-execution-history-modal {
  .MuiDialog-paperWidthLg {
    min-width: 1100px !important;
    max-width: 1100px !important;
    max-height: 80vh;

    .left-side {
      min-width: 500px;
      max-width: 500px;
      overflow-y: auto;
      max-height: calc(80vh - 200px);
    }

    .right-side {
      height: fit-content;
      max-height: calc(80vh - 200px);
      overflow-y: auto;
    }
  }
}
