.kemaro-robot-tags-list {
  .text-container {
    max-height: 20vh;
    overflow-y: auto;
  }

  .td-delete {
    width: 40px;
  }

  .badge {
    border: 1px solid;
    font-size: 0.85rem;
    font-weight: normal;

    &:hover {
      cursor: pointer;
    }
  }
}
